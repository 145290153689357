import { DockerContainerInfo, EC2Instance } from '@amzn/amazonmq-opsconsole-client';
import { Badge, Link, SpaceBetween, StatusIndicator, TextContent } from '@amzn/awsui-components-react';
import React from 'react';
import CopyToClipboardButton from '../../Components/copyToClipboardButton';

type Props = {
    instance: EC2Instance
}

function isBFS(imageName: string) {
    return imageName.endsWith("bfs")
}

function findRabbitContainer(dockerContainers: DockerContainerInfo[]): DockerContainerInfo | undefined {
    return dockerContainers.find(container => container.image.includes("amq-rabbitmq"))
}

function stripEcrPath(dockerImageName: string): string {
    let idx = dockerImageName.indexOf('/');
    if (idx < 0) {
        return dockerImageName;
    }
    return dockerImageName.substring(idx + 1);
}

const RabbitDockerImageField: React.FC<Props> = ({instance}) => {
    let rabbitContainer = findRabbitContainer(instance.dockerContainers)
    if (!rabbitContainer) {
        return (
            <StatusIndicator type="error">Could not find Rabbit container</StatusIndicator>
        )
    }
    return (
        <div className="awsui-util-label" style={{margin: 0}}>
            <div style={{display: 'flex', gap: 4, flexDirection: 'row', alignItems: 'center'}}>
                Docker Image:
                <Link onClick={() => {
                    
                }}>{stripEcrPath(rabbitContainer.image)}</Link>
                <CopyToClipboardButton text={rabbitContainer.image}/>
                {isBFS(rabbitContainer.image) &&
                    <Badge color="blue">BFS</Badge>
                }
            </div>
        </div>
    )
}

export default RabbitDockerImageField;